<template>
	<div class="app__row" >
		<div class="app__inner">
			<div class="page-game " >
				<div class="container" >
					<div class="game-container">
						
						
<div style="text-align: center;">
			    
</div>						
						  
						  
<!-- 表记录 -->
<div   class="statistic">
	<div class="tabs">
		<button class="tab" v-bind:class="table_active == 1?'active':''"  @click="get_mybets()">Withdrawals records</button>
		<button class="tab" v-bind:class="table_active == 2?'active':''"  @click="get_alladdress()">Earnings</button>
		<!-- <button class="tab">High roller</button> -->
		<!-- <button class="tab">Rare wins</button> -->
	</div>
	<div  class="statistic__body table-dice">
		<div  class="statistic__inner">
			<div   class="battles">
				<div   class="battle battle_active opened">
					
					<div  class="battle-table table-gamehistory" v-if="table_active == 1">
						<div  class="battle-table__head">
							<div  class="battle-table__column" style="width: 160px;">time</div>
							<!-- <div  class="battle-table__column">address</div> -->
							<div  class="battle-table__column" style="width: 320px;">address</div>
							<div  class="battle-table__column">number </div>
							<div  class="battle-table__column">payout</div>
						</div>
						<div  class="battle-table__inner">
							<div  class="battle-table__row" v-for="(item,index) in getextractApplyList ">
								<div  class="battle-table__row-inner">
									
									<div  class="battle-table__column" style="width: 160px;">
										<div >{{item.creation_time}}</div>
									</div>
									<div  class="battle-table__column" style="width: 320px;">
										<div>{{item.address}}</div>
									</div>
									<div  class="battle-table__column">
										<div>{{item.number}}</div>
									</div>
									<div  class="battle-table__column">
										<div v-if="item.state == 1">yes</div>
										<div v-else>no</div>
									</div>
									
								</div>
							</div>
							
							
						</div>
						<!-- 分页 -->
						<ul class="container-class"></ul>
					</div>
					
					
					<div  class="battle-table table-gamehistory" v-if="table_active == 2">
						<div  class="battle-table__head">
							<div  class="battle-table__column" style="width: 160px;">time</div>
							<div  class="battle-table__column">amount bets </div>
							<div  class="battle-table__column">payout</div>
						</div>
						<div  class="battle-table__inner">
							<div  class="battle-table__row" v-for="(item,index) in getearningsList ">
								<div  class="battle-table__row-inner">
									<div  class="battle-table__column" style="width: 160px;">
										<div>{{item.time}}</div>
									</div>
									<div  class="battle-table__column">
										<div>{{item.trx}}</div>
									</div>
									<div  class="battle-table__column">
										<!-- <div>{{item.id}}</div> -->
										<div v-if="item.state == 0">
											<button style="background-color: #f9e716;color: #52371b;width: 100px;height: 35px;border-radius: 30px;border: none;">领取</button>
										</div>
										<div v-else>
											<button style="background-color: #52371b;color: #ffffff;width: 100px;height: 35px;border-radius: 30px;border: none;">已领取</button>
											
										</div>
										
									</div>
								</div>
							</div>
							
							
						</div>
						<!-- 分页 -->
						<ul class="container-class"></ul>
					</div>
					
					
					
					
					
				</div>
																
																
																
			</div>
		</div>
	</div>
</div>
						
						
					
				
					</div>
				</div>
			</div>
		</div>
	</div>
	


</template>

<script>
	
	import request from '@/utils/request'
	import { Decimal } from 'decimal.js';//刚刚加入时IDEA可能还没反应所以显示灰色，过会就好了
	export default {
		name: 'Account',
		data() {
			return {
				getextractApplyList:{},
				getearningsList:{},
				table_active:1,
			};
		},
		

		created() {
			
			let _this = this;

			//_this.get_mybets();

		},
		
		methods: {
			//提现申请记录 参数page
			async get_mybets(){
				let _this = this;
				_this.table_active = 1;
				//提现申请记录 参数page
				request.post('user/extractApplyList', {
					page:1,
				}).then(res => {
					//console.log(res)
					if(res.data.code ==1){
						_this.getextractApplyList = res.data.data
					}
					
				});
			},
			//
			async get_alladdress(){
				let _this = this;
				_this.table_active = 2;
				
				//100天收益列表  参数page
				request.post('user/earningsList', {
					page:1,
				}).then(res => {
					//console.log(res)
					if(res.data.code ==1){
						_this.getearningsList = res.data.data
					}
					
				});
				
			},
			
			async getEarnings(id){
				let _this = this;
				_this.table_active = 2;
				//领取收益 参数id
				request.post('user/getEarnings', {
					id:id,
				}).then(res => {
					//console.log(res)
					if(res.data.code ==1){
						
					}
					
				});
				
			},
			


			// Block/getBlockList 
			// user/extractApplyList 提现申请记录 参数page
			// user/extractApply  参数 type  number
			// user/earningsList 100天收益列表  参数page
			// user/getEarnings 领取收益 参数id

			
			
			
			
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style  >

	.show_number{
		color: #F9E717;
	}
	.app__row {
	    height: 100%;
	    display: flex;
	    transition: filter .3s;
	    position: relative;
		
	}
	.app__row{
		/* background-image: url(../assets/images/could_bg.png); */
		background-repeat:repeat-x;
		background-color: #1A1613;
	}
	.app__row.chat-opened {
	    padding-right: 230px
	}
	@media(max-width:1100px) {
	    .app__row.chat-opened {
	        padding-right: 0
	    }
	}
	.app_iframe .app__row {
	    display: block
	}
	.app__row.profile-opened {
	    padding-right: 230px
	}
	@media(max-width:1100px) {
	    .app__row.profile-opened {
	        padding-right: 0
	    }
	}
	.modal-opened .app__row {
	    filter: blur(10px)
	}
	.app__inner {
	    overflow-y: auto;
	    overflow-x: hidden;
	    height: 100%;
	    flex: 1 1;
	    min-height: 400px;
	    z-index: 1;
	    /* padding-top: 60px; */
	    scrollbar-width: none
	}
	.app_logged .app__inner, .app_main .app__inner {
	    padding-top: 0
	}
	@media(max-width:1099.98px) {
	    .app__inner {
	        min-height: 0;
	        /* padding-top: 85px */
	    }
	}
	.app__inner::-webkit-scrollbar {
	    width: 0
	}
	
	.page-game{
		position: relative;
		min-height: calc(100vh - 60px);
	}
	.container {
		max-width: 1060px;
		padding: 0 20px;
		margin: 0 auto;
		position: relative;
	}
	@media (max-width: 767.98px){
		.container {
		    padding: 0 15px;
		}
	}


	.game-container {
		margin-bottom: 60px;
		/* padding-top: 50px; */
	}

	.game-container__row {
		display: flex;
	}
	@media (max-width: 1099.98px){
		.game-container__row {
		    justify-content: center;
		}
	}

	


	.game-container__left {
		flex: 1 1;
		/* padding-right: 30px; */
	}
	@media (max-width: 1099.98px){
		.game-container__left {
		    padding-right: 0;
		    flex: auto;
		    width: 100%;
		    max-width: 642px;
		}
	}


	.dapps-top__buttons {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-bottom: 15px;
	}
	@media (max-width: 1099.98px){
		.dapps-top__buttons {
		    display: none;
		}
	}


	.dapps-top__button {
		background-color: #161f2c;
		position: relative;
		border: none;
		overflow: visible;
		min-width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		padding: 8px;
		transition: all .3s;
		margin-right: 5px;
	}
	.game-board{
		width: 100%;
	}
	.dice {
		box-sizing: inherit;
	}

	.top {
		display: flex;
		align-items: center;
	}
	@media (max-width: 1099.98px){
		.top {
		    flex-direction: column;
		    height: auto;
		}
	}
	
	@media (max-width: 1099.98px){
		.dice-jackpot {
		    margin-top: 0;
		    margin-bottom: 15px;
		    min-height: 36px;
		    padding-left: 156px;
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		}
	}



/* 	.boardin {
		background: linear-gradient(270deg, rgba(41, 126, 229, .75), rgba(22, 31, 44, 0) 60%), url(../assets/images/board-bg.cfcf79bd.svg);
		background-size: cover;
		background-position: 50%;
		margin-bottom: 20px;
		height: 190px;
		border-radius: 15px;
		display: flex;
		align-items: center;
	} */
	@media (max-width: 1099.98px){
		.boardin {
		    height: 125px;
		    margin-bottom: 10px;
		}
	}
/* 	.boardout {
		background: linear-gradient(270deg,rgba(250,48,92,.75),rgba(22,31,44,0) 60%),url(../assets/images/board-bg.cfcf79bd.svg);
		background-size: cover;
		background-position: 50%;
		margin-bottom: 20px;
		height: 190px;
		border-radius: 15px;
		display: flex;
		align-items: center;
	} */
	@media (max-width: 1099.98px){
		.boardout {
		    height: 125px;
		    margin-bottom: 10px;
		}
	}


	.last-bets {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-bottom: 15px;
	}
	@media (max-width: 699.98px) {
		.last-bets{
			display: none;
		}
	}

	.last-bets span {
		margin-left: 8px;
		padding:12px 10px;
		text-align: center;
		min-width: 40px;
		line-height: 34px;
		height: 34px;
		background: #161f2c;
		border-radius: 8px;
		font-family: Roboto, serif;
		font-weight: 700;
		font-size: 15px;
	}
	
	.last-bets-span {
		margin-left: 8px;
		text-align: center;
		min-width: 40px;
		line-height: 34px;
		height: 34px;
		background: #161f2c;
		border-radius: 8px;
		font-family: Roboto, serif;
		font-weight: 700;
		font-size: 15px;
	}

	.in {
		color: #2283f6;
	}

	.out {
		color: #fa305c;
	}

	.last-bets__inner {
		/* position: absolute; */
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction:row-reverse;
		height: 34px;
	}

	.board__left {
		flex: 1 1;
	}

	.block-prediction {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.block-prediction span:first-child {
		height: 80px;
		font-family: Roboto;
		font-weight: 700;
		font-size: 70px;
		text-align: center;
		line-height: 80px;
		color: #fff;
	}

	.block-prediction span:nth-child(2) {
		margin-top: 5px;
		text-align: center;
		width: 100%;
		font-weight: 600;
		font-size: 15px;
		text-transform: uppercase;
		color: #55657e;
	}

	.board__center {
		width: 60px;
		position: relative;
		
	}
	@media (max-width: 1099.98px){
		.board__center {
			display: none;
			
		}
	}

	.board__right {
		flex: 1 1;
	}


	#dice span {
		position: absolute;
		margin: 100px 0 0 100px;
		display: block;
		font-size: 2.5em;
		padding: 10px
	}

	#dice{
		top: 50%;
		left: 50%;
		margin-top: -100px;
		margin-left: -100px;
		position: absolute;
		width: 200px;
		height: 200px;
		transform-style: preserve-3d;
		-webkit-animation: spin-data-v-09bc7c5f 50s linear infinite;
		animation: spin-data-v-09bc7c5f 50s linear infinite
	}

	.side {
		position: absolute;
		width: 200px;
		height: 200px;
		background: #fff;
		box-shadow: inset 0 0 40px #ccc;
		border-radius: 40px
	}

	#dice .cover,
	#dice .inner {
		background: #e0e0e0;
		box-shadow: none
	}

	#dice .cover {
		border-radius: 0;
		transform: translateZ(0)
	}

	#dice .cover.x {
		transform: rotateY(90deg)
	}

	#dice .cover.z {
		transform: rotateX(90deg)
	}

	#dice .front {
		transform: translateZ(100px)
	}

	#dice .front.inner {
		transform: translateZ(98px)
	}

	#dice .back {
		transform: rotateX(-180deg) translateZ(100px)
	}

	#dice .back.inner {
		transform: rotateX(-180deg) translateZ(98px)
	}

	#dice .right {
		transform: rotateY(90deg) translateZ(100px)
	}

	#dice .right.inner {
		transform: rotateY(90deg) translateZ(98px)
	}

	#dice .left {
		transform: rotateY(-90deg) translateZ(100px)
	}

	#dice .left.inner {
		transform: rotateY(-90deg) translateZ(98px)
	}

	#dice .top {
		transform: rotateX(90deg) translateZ(100px)
	}

	#dice .top.inner {
		transform: rotateX(90deg) translateZ(98px)
	}

	#dice .bottom {
		transform: rotateX(-90deg) translateZ(100px)
	}

	#dice .bottom.inner {
		transform: rotateX(-90deg) translateZ(98px)
	}

	.dot {
		position: absolute;
		width: 46px;
		height: 46px;
		border-radius: 23px;
		background: #444;
		box-shadow: inset 5px 0 10px #000
	}

	.dot.center {
		margin: 77px 0 0 77px
	}

	.dot.dtop {
		margin-top: 20px
	}

	.dot.dleft {
		margin-left: 134px
	}

	.dot.dright {
		margin-left: 20px
	}

	.dot.dbottom {
		margin-top: 134px
	}

	.dot.center.dleft {
		margin: 77px 0 0 20px
	}

	.dot.center.dright {
		margin: 77px 0 0 134px
	}

	@-webkit-keyframes spin-data-v-09bc7c5f {
		0% {
			transform: scale(.3) translateZ(-100px) rotateX(0deg) rotateY(0deg) rotate(0deg)
		}

		16% {
			transform: scale(.3) translateZ(-100px) rotateX(180deg) rotateY(180deg) rotate(0deg)
		}

		33% {
			transform: scale(.3) translateZ(-100px) rotateX(1turn) rotateY(90deg) rotate(180deg)
		}

		50% {
			transform: scale(.3) translateZ(-100px) rotateX(1turn) rotateY(1turn) rotate(1turn)
		}

		66% {
			transform: scale(.3) translateZ(-100px) rotateX(180deg) rotateY(1turn) rotate(270deg)
		}

		83% {
			transform: scale(.3) translateZ(-100px) rotateX(270deg) rotateY(180deg) rotate(180deg)
		}

		to {
			transform: scale(.3) translateZ(-100px) rotateX(1turn) rotateY(1turn) rotate(1turn)
		}
	}

	@keyframes spin-data-v-09bc7c5f {
		0% {
			transform: scale(.3) translateZ(-100px) rotateX(0deg) rotateY(0deg) rotate(0deg)
		}

		16% {
			transform: scale(.3) translateZ(-100px) rotateX(180deg) rotateY(180deg) rotate(0deg)
		}

		33% {
			transform: scale(.3) translateZ(-100px) rotateX(1turn) rotateY(90deg) rotate(180deg)
		}

		50% {
			transform: scale(.3) translateZ(-100px) rotateX(1turn) rotateY(1turn) rotate(1turn)
		}

		66% {
			transform: scale(.3) translateZ(-100px) rotateX(180deg) rotateY(1turn) rotate(270deg)
		}

		83% {
			transform: scale(.3) translateZ(-100px) rotateX(270deg) rotateY(180deg) rotate(180deg)
		}

		to {
			transform: scale(.3) translateZ(-100px) rotateX(1turn) rotateY(1turn) rotate(1turn)
		}
	}

	.controls {
		padding: 3px;
		background: #161f2c;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 20px 45px;
		position: relative;
	}

	.controls.result {
		justify-content: space-around;
		margin: 0 0 45px
	}

	.btn {
		border-radius: 7px;
		font-style: normal;
		transition: all .3s;
		color: #fff;
		font-weight: 600;
		white-space: nowrap;
		display: inline-block;
		text-decoration: none;
		text-align: center;
		border: 1px solid transparent;
		user-select: none;
		line-height: 1.4;
	}

	.btn_large {
		font-size: 18px;
		padding: 15px 30px;
	}

	.btn_blue {
		background-color: #2283f6;
	}

	.btn_red {
		border-color: #fa305c;
		background-color: #fa305c;
	}

	@media(max-width:575.98px) {
		.controls {
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 85px;
			justify-content: space-around
		}
	}

	.controls:after {
		left: 50%;
		top: 50%;
		margin-top: -17px;
		position: absolute;
		content: "";
		width: 1px;
		height: 34px;
		display: block;
		background-color: rgba(84, 101, 128, .25)
	}

	.controls .btn {
		position: relative;
		margin-top: -2px;
		margin-bottom: -2px;
		width: 170px;
		max-width: calc(50% - 5px)
	}

	@media(max-width:575.98px) {
		.controls .btn {
			width: calc(50% - 5px);
			max-width: none;
			position: absolute;
			padding-left: 5px;
			padding-right: 5px
		}
	}
	



	.controls div {
		display: flex;
		flex-direction: column
	}

	.controls div span:first-child {
		font-weight: 500;
		font-size: 12px;
		text-align: center;
		color: #55657e
	}

	.controls div span:nth-child(2) {
		font-family: Roboto;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
		color: #fff
	}

	.controls__left {
		transform: skewX(-11deg);
		margin-left: -20px
	}

	@media(max-width:575.98px) {
		.controls__left {
			transform: skewX(0deg);
			margin-left: 0;
			left: 0;
			top: calc(100% + 10px);
			width: 48%;
			height: 54px
		}
	}

	.controls__left span {
		/* display: block; */
		transform: skewX(11deg);
		font-size: 15px;
		text-transform: uppercase
	}

	@media(max-width:575.98px) {
		.controls__left span {
			transform: skewX(0deg)
		}
	}

	.controls__prediction {
		min-width: 90px;
		position: relative
	}

	.controls__chance {
		min-width: 90px
	}

	.controls__right {
		transform: skewX(11deg);
		margin-right: -20px
	}

	@media(max-width:575.98px) {
		.controls__right {
			transform: skewX(0deg);
			margin-right: 0;
			right: 0;
			top: calc(100% + 10px);
			width: 48%;
			height: 54px
		}
	}

	.controls__right span {
		/* display: block; */
		transform: skewX(-11deg);
		font-weight: 600;
		font-size: 15px;
		text-transform: uppercase
	}

	@media(max-width:575.98px) {
		.controls__right span {
			transform: skewX(0deg)
		}
	}

	.slider {
		position: relative
	}

	@media(max-width:575.98px) {
		.slider {
			margin-bottom: 20px
		}
	}

	.slider:after {
		margin-top: 20px;
		content: "";
		display: block;
		height: 7px;
		width: 100%;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHAQMAAAALYk4gAAAABlBMVEUAAAAnMEVBlWpGAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjaGDARAAmlQOB8JPswAAAAABJRU5ErkJggg==)
	}

	@media(max-width:575.98px) {
		.slider:after {
			display: none
		}
	}

	.noUi-target,
	.noUi-target * {
		-webkit-touch-callout: none;
		-webkit-tap-highlight-color: transparent;
		-webkit-user-select: none;
		touch-action: none;
		-ms-user-select: none;
		-moz-user-select: none;
		user-select: none;
		box-sizing: border-box
	}

	.noUi-target {
		position: relative;
		direction: ltr
	}

	.noUi-base,
	.noUi-connects {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1
	}

	.noUi-connects {
		overflow: hidden;
		z-index: 0
	}

	.noUi-connect,
	.noUi-origin {
		will-change: transform;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		-ms-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		-webkit-transform-style: preserve-3d;
		transform-origin: 0 0;
		transform-style: flat
	}

	.noUi-connect {
		height: 100%;
		width: 100%
	}

	.noUi-origin {
		height: 10%;
		width: 10%
	}

	html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
		left: auto;
		right: 0
	}

	.noUi-vertical .noUi-origin {
		width: 0
	}

	.noUi-horizontal .noUi-origin {
		height: 0
	}

	.noUi-handle {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		position: absolute
	}

	.noUi-touch-area {
		height: 100%;
		width: 100%
	}

	.noUi-state-tap .noUi-connect,
	.noUi-state-tap .noUi-origin {
		transition: transform .3s
	}

	.noUi-state-drag * {
		cursor: inherit !important
	}

	.noUi-horizontal {
		height: 10px;
		transition: opacity .2s
	}

	[disabled].noUi-horizontal {
		opacity: .85
	}

	.noUi-horizontal .noUi-handle {
		width: 30px;
		height: 30px;
		left: -15px;
		top: -10px
	}

	.noUi-vertical {
		width: 18px
	}

	.noUi-vertical .noUi-handle {
		width: 28px;
		height: 34px;
		left: -6px;
		top: -17px
	}

	html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
		right: -17px;
		left: auto
	}

	.noUi-target {
		background: #fa305c
	}

	.noUi-connects,
	.noUi-target {
		border-radius: 10px
	}

	.noUi-connect {
		background: #297fe5
	}
	.noUi-connect2 {
		background: #297fe5
	}

	.noUi-draggable {
		cursor: ew-resize
	}

	.noUi-vertical .noUi-draggable {
		cursor: ns-resize
	}

	.noUi-handle {
		cursor: pointer;
		transition: box-shadow .3s;
		background-color: #fff;
		border-radius: 7px;
		box-shadow: 0 0 0 4px hsla(0, 0%, 100%, .2)
	}

	.noUi-active {
		box-shadow: 0 0 0 5px hsla(0, 0%, 100%, .4)
	}

	.noUi-vertical .noUi-handle:after,
	.noUi-vertical .noUi-handle:before {
		width: 14px;
		height: 1px;
		left: 6px;
		top: 14px
	}

	.noUi-vertical .noUi-handle:after {
		top: 17px
	}

	[disabled].noUi-handle,
	[disabled] .noUi-handle,
	[disabled].noUi-target {
		cursor: not-allowed
	}

	.noUi-pips,
	.noUi-pips * {
		box-sizing: border-box
	}

	.noUi-pips {
		position: absolute;
		color: #999
	}

	.noUi-value {
		position: absolute;
		white-space: nowrap;
		text-align: center
	}

	.noUi-value-sub {
		color: #ccc;
		font-size: 10px
	}

	.noUi-marker {
		position: absolute;
		background: #ccc
	}

	.noUi-marker-large,
	.noUi-marker-sub {
		background: #aaa
	}

	.noUi-pips-horizontal {
		padding: 10px 0;
		height: 80px;
		top: 100%;
		left: 0;
		width: 100%
	}

	.noUi-value-horizontal {
		transform: translate(-50%, 50%)
	}

	.noUi-rtl .noUi-value-horizontal {
		transform: translate(50%, 50%)
	}

	.noUi-marker-horizontal.noUi-marker {
		margin-left: -1px;
		width: 2px;
		height: 5px
	}

	.noUi-marker-horizontal.noUi-marker-sub {
		height: 10px
	}

	.noUi-marker-horizontal.noUi-marker-large {
		height: 15px
	}

	.noUi-pips-vertical {
		padding: 0 10px;
		height: 100%;
		top: 0;
		left: 100%
	}

	.noUi-value-vertical {
		transform: translateY(-50%);
		padding-left: 25px
	}

	.noUi-rtl .noUi-value-vertical {
		transform: translateY(50%)
	}

	.noUi-marker-vertical.noUi-marker {
		width: 5px;
		height: 2px;
		margin-top: -1px
	}

	.noUi-marker-vertical.noUi-marker-sub {
		width: 10px
	}

	.noUi-marker-vertical.noUi-marker-large {
		width: 15px
	}

	.noUi-tooltip {
		display: block;
		position: absolute;
		white-space: nowrap;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 30px;
		height: 30px;
		line-height: 30px;
		pointer-events: none;
		font-family: Roboto;
		font-weight: 700;
		font-size: 12px;
		text-align: center;
		color: #191b20
	}

	.noUi-horizontal .noUi-tooltip {
		transform: translate(-50%);
		left: 50%;
		bottom: 120%
	}

	.noUi-vertical .noUi-tooltip {
		transform: translateY(-50%);
		top: 50%;
		right: 120%
	}
	
	.form{
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
	}


	.form .amount{
		width: calc(50% - 10px);
		margin-right: 10px;
	}
	@media (max-width: 575.98px){
		.form .amount {
			width: 100%;
			margin-right: 0;
		}
	}


	.amount{
		background: #161f2c;
		border: 1px solid #4b5b74;
		border-radius: 10px;
		display: flex;
		padding: 10px;
		position: relative;
	}
	.amount__left{
		display: flex;
		flex-direction: column;
	}
	@media (max-width: 575.98px){
		.amount__left {
			display: none;
		}
	}	


	.amount__btn{
		padding: 0;
		width: 40px;
		height: 25px;
		line-height: 23px;
		border: none;
		background-color: #283343;
		border-radius: 7px;
		font-weight: 600;
		font-size: 12px;
		color: #55657e;
		transition: all .3s;
		/* border: 1px solid transparent; */
		touch-action: manipulation;
	}
	.amount__btn:first-child{
		margin-bottom: 5px;
	}
	.amount__btn:active,.amount__btn:focus{
		background: #297fe5;
		color: #fff;

	}
	.amount__btn:hover{
		border: 1px solid #297fe5;
	}
	.amount__center{
		flex: 1 1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}


	.amount__center>div{
		display: flex;
		justify-content: center;
		margin-bottom: 5px;
	}
	.amount__center svg{
		width: 24px;
		height: 24px;
		margin-right: 5px;
	}
	.amount__center input{
		padding: 0;
		width: 120px;
		font-family: Roboto;
		font-weight: 700;
		font-size: 20px;
		color: #fff;
		background-color: transparent;
		border: none;
		outline: none;
	}
	.amount__center span{
		font-weight: 600;
		font-size: 14px;
		text-align: center;
		text-transform: uppercase;
		color: #55657e;
	}
	@media (max-width: 575.98px){
		.amount__center>span {
			text-align: left;
			min-width: 149px;
		}
	}
	.amount__right{
		display: flex;
		flex-direction: column;
	}
	.form .btn{
		width: calc(50% - 10px);
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	@media (max-width: 575.98px){
		.form .btn {
			padding: 5px;
		}
	}
	.form .btn>span:first-child{
		text-transform: uppercase;
		position: relative;
	}
	.form .btn>span{
		text-align: center;
		display: flex;
		align-items: center;
		line-height: 1;
	}
	.form .btn>span:nth-child(2){
		margin-top: 10px;
		font-weight: 500;
		font-size: 12px;
		text-transform: uppercase;
		color: hsla(0,0%,100%,.75);
	}
	.form .btn>span:nth-child(2) span {
	    display: inline-block;
	    margin: 0 .3em;
	}


	.battles{width:100%;margin-top:20px}
	
	@media (max-width:1100px) {
		.statistic{
			display: none;
		}
	}

	/* //滑块 */
	.el-slider__runway{
		background-color: #8a8a8a;
		height: 10px;
		
		
	}
		
	.el-slider__bar{
		height: 10px;
	}
	/* 点 */
	.el-slider__button-wrapper{
		
	}
	.el-slider__button{
		width: 20px;
		height: 20px;
		border: none;
		box-shadow: 0 0 0 4px hsl(0deg 0% 100% / 20%);
		/* border-radius: 7px; */
		
	}
/* 	.btn {
	    display: flex;
	    align-items: center;
	    color: inherit;
	    background-color: transparent;
	    border: 0;
	    padding: 5px 10px 5px 5px;
	    border-radius: 0
	} */
	
/* 	.btn:hover {
	    border-radius: 5px; */
	    /* background-color: #273141 */
/* 	}
	.btn>span { */
	    /* margin-right: 8px;
	    width: 19px;
	    height: 19px; */
	    /* background: #273141; */
/* 	    border-radius: 4px;
	    position: relative
	} */
	
/* 	.btn>span>svg { */
/* 	    display: block;
	    fill: #778cae;
	    position: absolute;
	    top: 4px;
	    left: 5px;
	    width: 10px;
	    height: 10px
	} */

</style>
